import mixpanel from 'mixpanel-browser';

const init = () => {
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || '', {
        debug: true
    });
};
init();

const trackEvent = (eventName = '', dataObj = {}) => {
    try {
        mixpanel.track(eventName, dataObj);
    } catch (e) { console.log(e); }
};

module.exports = {
    trackEvent
};
