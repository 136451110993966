import Router from 'next/router';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const getAuth = () => {
    try {
        if (typeof window !== 'undefined' && localStorage && localStorage.getItem('AUTH')) {
            return JSON.parse(localStorage.getItem('AUTH'));
        }
        return null;
    } catch (e) {
        return null;
    }
};

const setAuth = (value) => {
    typeof window !== 'undefined' && localStorage.setItem('AUTH', value);
};

const removeAuth = () => {
    typeof window !== 'undefined' && localStorage.removeItem('AUTH');
    cookies.remove('AUTH', { path: '/' });
    cookies.remove('RT', { path: '/' });
};
const isAuthenticated = () => {
    const auth = getAuth();
    return auth != null && auth.token;
};

const logout = () => {
    removeAuth();
    Router.push('/api/auth/logout');
};

export const getRefreshToken = () => {
    const auth = cookies.get('RT');
    return auth;
};

export const removeRT = () => {
    cookies.remove('RT', { path: '/' });
};

export const setRefreshToken = (refreshToken) => {
    cookies.set('RT', refreshToken, { path: '/' });
    return refreshToken;
};

export {
    getAuth, isAuthenticated, logout, removeAuth, setAuth
};
