import { Navigation2 } from 'react-feather';
import Text from '../atoms/Text';

const TooltipWithArrow = ({ childNode, tooltipText }) => (
    <div className="relative group">
        {childNode}
        <div className='absolute hidden group-hover:block bg-gray-600 right-[54px] rounded top-0.5'>
            <div className='relative'>
                <Text variant="bodySmall" className="text-basic-100 px-5 py-2 z-[80] text-nowrap">{tooltipText}</Text>
                <Navigation2 size={20} className='absolute -right-3 top-2 rotate-90 text-gray-600 fill-gray-600 z-0' />
            </div>
        </div>
    </div>
);

export default TooltipWithArrow;
